import React, { useState, useEffect, useContext } from "react";
import { ResultsContext } from "./Context/ResultsContext";
import { SearchContext } from "./Context/SearchContext";
import { get_search_results } from "../Services/SearchAPI";
import Card from "./Card";
import NoResults from "./NoResults";

const Results = () => {

    const [resultsContext, setResultsContext] = useContext(ResultsContext);
    const [searchContext, setSearchContext] = useContext(SearchContext);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const url = new URL(location);

    useEffect(async () => {

        setIsLoading(true);
        let data = await get_search_results(searchContext);
        setIsLoading(false);

        let resultData = {
            ...resultsContext,
        };

        resultData.current_page = data.pagination.current_page;
        resultData.total_results = data.pagination.total_results;
        resultData.total_pages = data.pagination.total_pages;
        resultData.results = data.results;

        setResultsContext(resultData);
        url.searchParams.delete("page");
        history.pushState({}, "", url);

    }, [searchContext]);

    useEffect(async () => {

        if(resultsContext.results) {
            setResults(resultsContext.results);
        }
        
    }, [resultsContext]);

    return (

        <div className="container container--flush">

            <div className="news-listing">

                { results.length > 0 ? (
                    
                    <>
                    {results.map((result) => {
                        return (
                            <div key={result.id} className="news-listing__item">
                                <Card post={result}/>
                            </div>
                        );
                    })}
                    </>

                ) : (

                    <>
                    
                        { !isLoading ? (
                            <NoResults/>
                        ): (
                            <div class="loader-wrapper"><div className="loader"></div></div>
                        )}

                    </>

                )}
                
            </div>

        </div>
        
    );
  };
  
  export default Results;

import React, { useState, useEffect, useContext } from "react";
import { SearchContext } from "./Context/SearchContext";
import { SearchContextDefaultData } from "./Context/SearchContextDefaultData";
import { ResultsContext } from "./Context/ResultsContext";
import { ResultsContextDefaultData } from "./Context/ResultsContextDefaultData";
import Header from "./Header";
import Results from "./Results";
import Pagination from "./Pagination";

const SearchProvider = ({ initialState, children }) => {

  const searchState = useState({
    ...SearchContextDefaultData,
    ...initialState,
  });
  
  return (
    <SearchContext.Provider value={searchState}>
      {children}
    </SearchContext.Provider>
  );

};

const ResultsProvider = ({ children }) => {

    const resultsState = useState({
      ...ResultsContextDefaultData,
    });
    
    return (
      <ResultsContext.Provider value={resultsState}>
        {children}
      </ResultsContext.Provider>
    );
  
  };

const Search = ({ initialState }) => {

  return (
    <div className="search">
        <SearchProvider initialState={initialState}>
            <ResultsProvider>
                <Header/>
                <Results />
                <Pagination />
            </ResultsProvider>
        </SearchProvider>
    </div>
  );
};

export default Search;

import React, { useState, useEffect, useContext } from "react";
import { SearchContext } from "./Context/SearchContext";
import { get_search_filters } from "../Services/SearchAPI";

const Filters = () => {

    const [searchContext, setSearchContext] = useContext(SearchContext);
    const [filters, setFilters] = useState([]);
    const [active, setActive] = useState(null);

    useEffect(async () => {
        let searchFilters = await get_search_filters();
        setFilters(searchFilters.categories);
    }, []);

    useEffect(async () => {

        if(searchContext.category) {
            setActive(searchContext.category);
        }
        
    }, [searchContext]);

    const handleSelection = (id) => {
        setActive(id);

        let data = {
            ...searchContext,
        };
    
        data.category = id;
        data.current_page = 1;
        setSearchContext(data);

    };

    return (
        <nav className="news-filter__nav">

            <span className="news-filter__link" onClick={() => handleSelection(null)} data-active={ active == null ? 'true' : 'false' }>All</span>

            {filters.map((filter) => {
                return (
                    <span key={filter.id} onClick={() => handleSelection(filter.id)} data-active={ active == filter.id ? 'true' : 'false' } className="news-filter__link">{filter.name}</span>
                );
            })}
        
        </nav>
    );
  };
  
  export default Filters;

import React from 'react';
import ReactRender from '../tools/react-render';
import Search from './Search';


const searchListings = document.querySelectorAll('[data-behaviour="react-search"]');

if (searchListings.length > 0) {

    searchListings.forEach((searchListing) => {
        let config = JSON.parse(searchListing.getAttribute('data-config') ?? '{}');
        ReactRender(<Search config={config} />, searchListing);
    });
    
}

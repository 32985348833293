import React from "react";

const NoResults = () => {

    return (
        <div className="news-listing__item news-listing__item--full">
            <div className="news-card news-card--empty">
                <div className="news-card__content">
                    <h3 className="news-card__title">No Results to display</h3>
                    <p>Adjust your search terms or try a different category</p>
                </div>
            </div>
        </div>
    );
  };
  
  export default NoResults;

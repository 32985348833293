import React, { useState, useEffect, useContext } from "react";
import { SearchContext } from "./Context/SearchContext";

const Sort = () => {

    const [searchContext, setSearchContext] = useContext(SearchContext);

    const sortOptions = [
        { value: "latest", label: "Latest first" },
        { value: "oldest", label: "Oldest first" },
        { value: "title", label: "Sort by Title" },
    ];

    const handleSortChange = (e) => {

        let data = {
            ...searchContext,
        };
    
        data.sort = e.target.value
        setSearchContext(data);

    }

    return (
        
        <select className="news-filter__dropdown select" onChange={(e) => handleSortChange(e)}>
            {sortOptions.map((option) => {
                return (
                    <option key={option.value} value={option.value}>{option.label}</option>
                );
            })}

        </select>
        
    );
  };
  
  export default Sort;

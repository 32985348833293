import React, { useState, useEffect, useContext } from "react";

import Filters from "./Filters";
import Sort from "./Sort";

const Header = ( searchFilters ) => {

    return (

        <div id="news-filters" className="news-filter">

            <div className="news-filter__container container">
                
                <Filters searchFilters={searchFilters} />
            
                <div className="news-filter__sort">
                    <Sort />
                </div>

            </div>

        </div>

    );

};
  
export default Header;

import React, { useState, useEffect, useContext } from "react";
import { SearchContext } from "./Context/SearchContext";
import { ResultsContext } from "./Context/ResultsContext";
import { get_search_results } from "../Services/SearchAPI";

const Pagination = () => {

    const url = new URL(location);
    const [searchContext, setSearchContext] = useContext(SearchContext);
    const [resultsContext, setResultsContext] = useContext(ResultsContext);
    
    const handleLoadMore = async () => {

        const currentPage = resultsContext.current_page;

        let currentSearch = {
            ...searchContext,
        };

        currentSearch.current_page = currentPage + 1;

        let data = await get_search_results(currentSearch);
        
        let resultsData = {
            ...resultsContext,
        };

        const pagedResults = [...resultsData.results, ...data.results];
        resultsData.results = pagedResults;
        resultsData.current_page = currentPage + 1;
        
        url.searchParams.set("page", resultsData.current_page);
        history.pushState({}, "", url);

        setResultsContext(resultsData);
        
    };

    return (

        <>
            { resultsContext.current_page < resultsContext.total_pages ? ( 
                <nav className="pagination">

                    <div className="container pagination__wrapper">

                        <span className="button button--outline pagination__next" onClick={() => handleLoadMore()}>
                            <span className="pagination__text">Load more</span>
                        </span>

                    </div>

                </nav>
            ) : null }  

        </>

    );
  };
  
  export default Pagination;

import React from "react";

const Card = ( data ) => {

    const post = data.post;

    return (
        <a className="news-card" href={ post.url }>
            
            <div className="news-card__media">
                { post.thumbnail ? (
                    <img className="news-card__thumbnail" src={ post.thumbnail } alt={ post.title } />
                ) : null }
            </div>

            <div className="news-card__content">
                
                <div className="news-card__meta">

                    { post.categories ? (
                        <ul className="news-card__categories categories-list">
                            { post.categories.map((category) => {
                                return (
                                    <li key={category.id} className="categories-list__item">{ category.name }</li>
                                )
                            })}
                        </ul>
                    ) : null }

                    <h3 className="news-card__title">{ post.title }</h3>

                </div>
                
                <span className="news-card__button button button--text button--arrow-right">Read more</span>

            </div>
        
        </a>
    );
  };
  
  export default Card;
